@use "../../../zIndexes.scss" as ZINDEXES;

// TODO: use colors.scss
$FONT_COLOR: #687f99;
$FONT_COLOR2: #29323c;
$FONT_COLOR3: #485563;

.footer {
  overflow: hidden;
  box-sizing: border-box;
  z-index: ZINDEXES.$ZINDEX_FOOTER;
  box-shadow: inset 0px 24px 24px black;
  background-color: #1a1f25;
  background-image: linear-gradient(to top, #29323c 100%, #485563 0%);
  // background: url("./img/bg-footer.jpg") repeat;
  // background-position: 0 -50px;
  // background-size: 16%;
}

.content {
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 12px;
  color: $FONT_COLOR;
  text-shadow: 1px 1px #29323c;
  @media screen and (max-width: 800px) { font-size: 12px; }
  @media screen and (max-width: 500px) { font-size: 9px; }
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  > * {
    margin: 8px 24px;
    @media screen and (max-width: 800px) { margin: 4px 12px; }
    @media screen and (max-width: 500px) { margin: 2px 6px; }
  }
}

.brand {
  font-size: 18px;
  @media screen and (max-width: 800px) { font-size: 14px; }
  @media screen and (max-width: 500px) { font-size: 12px; }
  white-space: nowrap;
}

.buttons,
.links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.buttons {
  a, a svg {
    height: 40px;
    @media screen and (max-width: 800px) { height: 30px; }
    @media screen and (max-width: 500px) { height: 20px; }
  }
  a {
    margin: 8px 16px;
    @media screen and (max-width: 800px) { margin: 4px 12px; }
    @media screen and (max-width: 500px) { margin: 2px 6px; }
  }
  a svg {
    fill: $FONT_COLOR;
    stroke: $FONT_COLOR2;
    stroke-width: .5;
  }
  a:hover svg { stroke: white; }
}

.links {
  a {
    margin: 8px;
    @media screen and (max-width: 800px) { margin: 4px; }
    @media screen and (max-width: 500px) { margin: 2px; }
    white-space: nowrap;
    color: $FONT_COLOR;
    text-decoration: underline;
    &:hover { color:white; }
  }
}

/* stylelint-disable no-descending-specificity */
.buttons svg,
.links a {
  transition: filter .2s;
  filter: drop-shadow(2px 2px 5px #29323c);
  &:hover { filter: drop-shadow(1px 1px 4px #888); }
}
/* stylelint-enable no-descending-specificity */

.version,
.copyright {
  padding: 8px 0;
  @media screen and (max-width: 800px) { padding: 4px; }
  @media screen and (max-width: 500px) { padding: 2px; }
  white-space: nowrap;
}
