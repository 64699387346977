.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  video {
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
  }
}
