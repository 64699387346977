.window {
  padding: 12px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
  color: white;
}

.header {
  display: flex;
  justify-content: center;
  line-height: 32px;
  > * { height: 32px; margin: 0 12px; }
  margin: 12px 0;
  font-size: 30px;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: capitalize;
}

.footer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  &>* { margin: 0 4px; }
  font-size: 12px;
}

.content {
  padding: 8px;
  line-height: 28px;
  h1,h2,h3 {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
  h1 {
    font-size: 24px;
    text-decoration: none;
    border-bottom: solid white 2px;
  }
  h2 { font-size: 20px; }
  h3 { font-size: 18px; font-weight: normal; }
  p {
    margin: 12px;
    text-align: justify;
    padding: 0;
  }
  ul,ol {
    display: block;
    li { margin: 8px 0; }
  }
}
