.box {
  display: block;
  padding-bottom: 5px; // TODO: fixes bottom border. Find out, why this is required
}

.footNote {
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: #888;
  a {
    color: #888;
    text-decoration: underline;
  }
}
