.entry {
  // box-shadow: inset 0 0 8px #fff;
  border-bottom: solid gray 1px;
  padding: 12px;
}

.header {
  display: flex;
  padding: 0px 8px;
  > * { margin: 2px 4px; }
}

.title {
  font-weight: bold;
}

.content {
  border-top: solid gray 1px;
  font-size: 16px;
  padding: 8px 24px;
}

.spinner {
  margin: 8px;
}
.badge {
  display: inline-block;
  color: white;
  padding: 2px 6px;
  margin: 2px 4px;
  line-height: 14px;
  font-size: 12px;
  background-color: #444;
  border-radius: 12px;
}
