.countdown {
  $textBorderColor: black;
  font-family: sans-serif;
  font-weight: 100;
  padding: 10px 4px;
  margin: 0 auto;
  text-align: center;
  color: white;
  background-color: rgba(0,0,0,0.4);
  border-radius: 8px;
  box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.8);
  text-shadow: -1px 0 $textBorderColor, 0 1px $textBorderColor, 1px 0 $textBorderColor, 0 -1px $textBorderColor;
  overflow: hidden;
}

.title {
  font-size: 32px;
  line-height: 32px;
  letter-spacing: 1px;
  font-family: "fhx4", sans-serif;
}

.subTitle {
  font-size: 16px;
  letter-spacing: 1px;
}

.segment {
  border-radius: 8px;
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.8);
  width: 86px;
  font-size: 40px;
  line-height: 40px;
  display: inline-block;
  margin: 4px 8px;
  overflow: hidden;
  text-transform: uppercase;
  background: rgba(0,0,0,0.5);
  .identifier {
    font-size: 16px;
    line-height: 16px;
  }
}

.closeToFin {
  .value {
    position: relative;
    color: red;
    animation: blink 1s linear infinite;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
