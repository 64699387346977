.entries {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  > * { margin: 16px 8px; }
}

.entryBoxContent {
  width: 280px;
  min-height: 100px;
  img { width: 280px; }
}
