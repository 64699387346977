@use "../../assets.scss" as ASSETS;
@use "./fonts";

/* stylelint-disable selector-pseudo-class-no-unknown */
:global {
  .grecaptcha-badge {
    visibility: hidden;
  }
}

.app {
  height: 100%;
  user-select: auto;
  font-size: 16px;
  color: white;
  a {
    color: #039be5; // TODO: colors.scss
    text-decoration: none;
    &:hover { text-decoration: underline; }
  }
}
