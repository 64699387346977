.filters {
  display: flex;
  align-items: center;
  > * {
    margin: 8px;
  }
}

.offers {
  margin: 24px;
  table {
    width: 100%;
  }
  table tr {
    overflow: hidden;
  }
  table thead td {
    font-weight: bold;
    border: solid white 1px;
  }
  table tbody td {
    padding: 4px;
    overflow: hidden;
  }
}
