@use "../../../../../colors.scss" as COLORS;

.logo {
  img {
    width: 100%;
    height: auto;
    filter: drop-shadow(0 0 2px COLORS.$HEADER_PURPLE);
    transition: filter .2s;
    &:hover { filter: drop-shadow(0 0 4px COLORS.$HEADER_PURPLE); }
  }
}
