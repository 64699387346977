$___COLOR_EXAMPLE_PRIVATE: #FFF;

$COLOR_EXAMPLE: $___COLOR_EXAMPLE_PRIVATE;

// Layout
$HEADER_PURPLE: #161741;

// Classes
$ARCHER: #55C1BD;
$CLERIC: #FEFDAB;
$ENCHANTER: #979797;
$KNIGHT: #2e3968;
$NECROMANCER: #363636;
$PALADIN: #615C73;
$SUMMONER: #04537B;
$THIEF: #885656;
$WARRIOR: #D2975D;
$WIZARD: #E55929;

// Social
$DISCORD: #6E85D2;
$FACEBOOK: #4867AA;
$TWITTER: #5DA9DD;
$YOUTUBE: #FF0000;
$TWITCH: #9146FF;
$GITLAB: #F46A25;
