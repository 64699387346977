.content {
  overflow: hidden;
}

.contentIsLoading {
  display: inline;
  text-align: center;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.errorBoundary {
  width: 100%;
  position: absolute;
}
