
$fontBorderColor: #222;

.subHeaderRow {
  width: 100%;
  margin: 30px 0;
  font-weight: 300;
  font-size: 1.64rem;
  letter-spacing: 2px;
  text-align: center;
  text-shadow: -1px 0 $fontBorderColor, 0 1px $fontBorderColor, 1px 0 $fontBorderColor, 0 -1px $fontBorderColor;
}
