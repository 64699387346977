@use "../../../../../../assets.scss" as ASSETS;

.entry {
  margin: 4px 0;
  &:first-of-type {
    margin-top: 0px;
  }
}

.question {
  font-size: 18px;
  line-height: 28px;
  display: flex;
  justify-content: flex-start;
  svg {
    font-size: 28px;
  }
  cursor: url(ASSETS.$ASSETS_CURSORS+"/interface.gif"), pointer;
  &:hover {
    text-decoration: underline;
  }
  .collapseExpandButton {
    display: inline;
  }
  .questionContent {
    margin-left: 8px; // TODO: RTL
  }
}

.answer {
  overflow: hidden;
  transition: height 1s ease-out;
  text-align: justify;
  font-size: 18px;
  margin: 0 12px 12px 12px;
  padding: 8px;
  padding-left: 24px; // TODO: RTL
  border-left: dashed white 2px; // TODO: RTL
  a {
    color: white;
  }
}
