@use "../../../zIndexes.scss" as ZINDEXES;

.view {
  height: 100vh;
  margin: 0 auto;
  background-color: #29323c; // TODO: colors.scss
  background-image: radial-gradient(closest-side, #485563 0%, #29323c 100%); // TODO: colors.scss
  font-size: 12px;
  font-family: Arial,Helvetica,sans-serif;
}

.backgroundVideo {
  z-index: ZINDEXES.$ZINDEX_BACKGROUND_VIDEO;
}

.content {
  position: relative;
}

.window {
  text-align: center;
  p { text-align:  center; }
  svg { font-size: 64px; }
}
