.window {
  max-width: 350px;
  .inputWrapper {
    display: block;
    input { width: 95%; }
  }
}

.spinner {
  text-align: center;
}

.status {
  width: 300px;
  margin: 12px 0 12px 8px;
  padding: 4px 8px;

  display: flex;
  align-items: center;
  &.ok{
    color: #0A0;
    border: solid #0A0 1px;
  }
  &.error{
    color: #A00;
    border: solid #A00 1px;
  }
}

.statusIcon {
  margin: 0 8px;
}
