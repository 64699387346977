.wrapper {
  display: block; // TODO: inline-block;
  padding: 8px;
}

.label {
  display: block;
  color: #888;
  margin: 0 8px;
}

.wrapper .select {
  min-width: 200px;
}
