// Layout
$ZINDEX_HEADER: 100;
$ZINDEX_FOOTER: 100;
$ZINDEX_CONTENT: 90; // Needed to display figures in front of background and behind content correctly.
$ZINDEX_NOTIFICATION_AREA: 300;
$ZINDEX_RESPONSIVE_MENU: 500;
$ZINDEX_MODALS_AREA: 700;

// Random
$ZINDEX_FIGURE_BEHIND_CONTENT: -1;

// HomePage
$ZINDEX_KEY_FEATURES_ARROW: 50;
$ZINDEX_BACKGROUND_VIDEO: 0;
$ZINDEX_TITLE_VIDEO: -1;
