@use "../../../globals.scss" as GLOBALS;
@use "../../../zIndexes.scss" as ZINDEXES;

.view {
  background-color: #29323c; // TODO: colors.scss
  background-image: radial-gradient(closest-side, #485563 0%, #29323c 100%); // TODO: colors.scss
  margin: 0 auto;
}

.backgroundVideo {
  z-index: ZINDEXES.$ZINDEX_BACKGROUND_VIDEO;
}

.snowAnimation {
  position: fixed;
}

.headerContentFooterWrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.content {
  padding-top: GLOBALS.$HEADER_HEIGHT;
  z-index: ZINDEXES.$ZINDEX_CONTENT;
  opacity: 1;
  flex-grow : 1;
}

.footer {
  bottom: 0;
  width: 100%;
}
