.headerRow {
  width: 100%;
  font-size: 45px;
  text-align: center;
  font-family: "fhx4", sans-serif;
  letter-spacing: 1px;
  color: white;
  background-color: rgba(0,0,0,0.4);
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
