@use "../../../../../assets.scss" as ASSETS;

.player {
  display: inline-block;
  font-family: sans-serif;
  font-size: 12px;
  user-select: none;
}

.buttonsAndVolume {
  display: flex;
  align-items: center;
}

.button {
  stroke: black;
  stroke-width: 12px;
  transition: filter .2s;
  &:hover { filter: drop-shadow(1px 1px 2px #161741); }
  &:not(:disabled) { cursor: url(ASSETS.$ASSETS_CURSORS+"/interface.gif"), pointer; }
  &.play, &.pause {
    $size: 42px;
    width: $size;
    height: $size;
    background-size: $size $size;
  }
  &.next, &.prev {
    $size: 24px;
    width: $size;
    height: $size;
    background-size: $size $size;
  }
  &.volUp, &.volDown {
    $size: 22px;
    width: $size;
    height: $size;
    background-size: $size $size;
  }
}

.buttons {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 145px;
  white-space: nowrap;
  .button { margin: 1px; }
}

.volume {
  text-align: center;
  margin: 0 1px;
  .value {
    text-align: center;
    width: 20px;
    &::after { content: "%" }
  }
}

.title { text-align: center; }
