@use "../../../../colors.scss" as COLORS;

.window {
  color: inherit;
}

.reducer {
  margin-bottom: 24px;
}

.spinner {
  margin: 24px;
  text-align: center;
}

.entries {
  box-shadow: inset 0 2px 8px rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  max-height: 500px;
  padding: 2px;
  overflow-y: auto;
}
