// TODO: use colors.scss
.wrapper {
  display: inline-block;
  padding: 8px;
}

.label {
  display: block;
  color: #888;
  margin: 0 8px;
}

.wrapper .input {
  color: white;
  background-color: rgba(150,150,150,0.2);
  appearance: none;
  outline: none;
  padding: 4px 8px;
  height: 32px;
  letter-spacing: 1px;
  border: solid #888 1px;
  border-radius: 2px;
  font: inherit;
  font-size: 16px;

  &:focus, &:active {
    color: white;
  }

  &::placeholder {
    color: #777;
  }

  &:disabled {
    color: #333;
    border-color: #333;
    background-color: rgba(0,0,0,0.2);
    &::placeholder {
      color: #333;
    }
  }

  &[type=password] {
    letter-spacing: 2px;
    &::placeholder {
      letter-spacing: 1px;
    }
  }
}

.tooltip {
  color: #AAA !important;
  border: solid #888 1px !important;
  border-radius: 8px;
  background-color: rgba(150,150,150,0.2)  !important;
  background-color: rgba(20,20,20,2) !important;
  box-shadow: 0 0 12px 8px rgba(3,155,229,0.25);
}

.tooltipContent {
  font-family: sans-serif;
  font-size: 14px;
  text-align: start;
  ul {
    line-height: 16px;
    margin: 0;
    padding: 0;
  }
}

.wrapper.ok .input {
  color: #080;
  border-color: #080;
}

.wrapper.warning .input {
  color: #850;
  border-color: #850;
}

.wrapper.error .input {
  color: #800;
  border-color: #800;
}

.wrapper .input:not(:disabled):focus,
.wrapper .input:not(:disabled):active,
.wrapper.ok .input:focus,
.wrapper.ok .input:active,
.wrapper.warning .input:focus,
.wrapper.warning .input:active,
.wrapper.error .input:focus,
.wrapper.error .input:active {
  border-color: #039be5;
  box-shadow: 0 0 12px 4px rgba(3,155,229,0.25);
}

.wrapper.ok .input:disabled {
  color: #040;
  border-color: #040;
}

.wrapper.warning .input:disabled {
  color: #430;
  border-color: #430;
}

.wrapper.error .input:disabled {
  color: #400;
  border-color: #400;
}
