.page {
  color: inherit;
  text-align: center;
}

.header {
  $fontBorderColor: black;
  width: 100%;
  font-size: 45px;
  text-align: center;
  font-family: "fhx4", sans-serif;
  letter-spacing: 1px;
  color: white;
  background-color: rgba(0,0,0,0.4);
  text-shadow: -1px 0 $fontBorderColor, 0 1px $fontBorderColor, 1px 0 $fontBorderColor, 0 -1px $fontBorderColor;
}

.subHeader {
  $fontBorderColor: #222;
  width: 100%;
  margin: 30px 0;
  font-weight: 300;
  font-size: 1.64rem;
  letter-spacing: 2px;
  text-align: center;
  text-shadow: -1px 0 $fontBorderColor, 0 1px $fontBorderColor, 1px 0 $fontBorderColor, 0 -1px $fontBorderColor;
}

.content {
  padding: 12px 2%;
  //border: dashed red 4px;
}

.content > .row {
  margin: 24px 0;
  // border: dashed yellow 2px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  > * { margin: 12px 8px; }
}
