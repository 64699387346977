.serverStatus {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.serverStatus > * {
  margin: 1px 0;
}

.tooltip {
  /* can use !important to overwrite react-tooltip's default class */
  padding: 0 !important;
  border: none !important;
  box-shadow: 4px 4px 8px black !important;
}

.tooltipContentBoxContent {
  padding: 4px;
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    align-items: center;
    *:first-of-type { margin: 4px; }
  }
}
