@use "../../../globals.scss" as GLOBALS;
@use "../../../zIndexes.scss" as ZINDEXES;
@use "../../../colors.scss" as COLORS;

.header {
  top: 0;
  position: fixed;
  width: 100%;
  height: GLOBALS.$HEADER_HEIGHT;
  z-index: ZINDEXES.$ZINDEX_HEADER; // TODO: required for blinking countdown
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  background-image: linear-gradient(
    to bottom,
    rgba(255,255,255,1) 0%,
    rgba(241,241,241,1) 50%,
    rgba(225,225,225,1) 51%,
    rgba(246,246,246,1) 100%
  );
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.8);
}

.serverTime {
  position: absolute;
  margin: 0 28px;
  bottom: 0px;
  color: COLORS.$HEADER_PURPLE;
}

.content {
  width: 100%;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: COLORS.$HEADER_PURPLE;
}

.content > * {
  margin: 0 16px;
}

.content > .navigationBtn {
  display: none;
  color: COLORS.$HEADER_PURPLE;
  transition: filter .2s;
  &:hover { filter: drop-shadow(1px 1px 4px COLORS.$HEADER_PURPLE); }
  svg {
    width: 32px;
    height: 32px;
  }
}
.content > .logo { max-width: 500px; }
.content > .navigation {
  flex-grow: 1;
  overflow: hidden;
}

@media screen and (max-width: 1700px) and (min-width: 1501px) {
  .content > *.musicPlayer { display: none; }
}

@media screen and (max-width: 1500px) and (min-width: 1201px) {
  .content > *.musicPlayer,
  .content > *.serverStatus {
    display: none;
  }
}

@media screen and (max-width: 1200px) and (min-width: 801px) {
  .content > *:not(.logo):not(.navigation) { display: none; }
}

@media screen and (max-width: 800px) {
  .content { justify-content: center; }
  .content > *:not(.logo):not(.navigationBtn) { display: none; }
  .content > .navigationBtn { display: inline-block; }
  .serverTime { display: none; }
}

.responsiveMenu {
  transition: margin .5s;
  margin: 0 -100%;
  &.visible { margin: 0; }
}
