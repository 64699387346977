.responsiveVideo {
  display: block;
}

.videoWrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.video {
  position: absolute;
  top: 0;
  left: 0;
}
