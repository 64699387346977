.statusIcon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 35px;
  background-position: 6px 16px;
  box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.2), inset -0.2em -0.2em 0.2em 0 rgba(0,0,0,0.5);
  &.OK {background-color: #4CAF50; }
  &.ADEQUATE { background-color: #FA0; }
  &.BAD { background-color: #dc1010; }
}
