.window {
  max-width: 600px;
}

.imgAndText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  > * { margin: 8px; }
  .image {
    max-width: 200px;
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.25));
  }
  .text { max-width: 400px; }
}
