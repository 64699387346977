@font-face {
  font-family: "fhx3";
  src: url("./fonts/fhx3.woff") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "fhx4";
  src: url("./fonts/fhx4.woff") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "blackChancery";
  src: url("./fonts/black_chancery.ttf") format("truetype");
  font-display: swap;
}
