.inputWrapper {
  width: 296px;
  height: 87px;
  text-align: center;
  &.username { background: url("./img/bg-username.jpg"); }
  &.password { background: url("./img/bg-pw.jpg"); }
  &.email { background: url("./img/bg-email.jpg"); }
  &.confirm { background: url("./img/bg-confirm.jpg"); }
  input {
    border-width: 2px;
    opacity: 1;
    width: 229px;
    padding: 2px;
    height: 16px;
    margin-top: 51px;
    text-align: center;
    color: white;
    font-size: 15px;
    font-weight: bold;
    font-family: monospace;
    background-color: rgba(0,0,0,0.2);
    &::placeholder {
      color: #CCC;
      opacity: 0.5;
    }
    &:focus, &:active {
      outline: none;
      border:unset !important;
      box-shadow:unset !important;
    }
    border: solid #333 2px;
    border-radius: 2px;
  }
  &.isInvalid input {

    animation: border-pulsate 1.5s infinite;
  }
}

@keyframes border-pulsate {
  0%   { border-color: rgba(200, 0, 0, 1); }
  50%  { border-color: rgba(200, 0, 0, 0); }
  100% { border-color: rgba(200, 0, 0, 1); }
}
