@use "../../../../../assets.scss" as ASSETS;

.languageSelector {
  display: flex;
  align-items: center;
  label {
    svg {
      margin: 0 4px;
      min-width: 16px;
    }
  }
}

.select {
  width: 130px;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
  color: black;
  * { cursor: url(ASSETS.$ASSETS_CURSORS+"/interface.gif"), pointer; }
  img {
    width: 16px;
    height: auto;
  }
}
