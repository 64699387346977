@use "../../../../colors.scss" as COLORS;

.root {
  color: inherit;
}

.textareaWrapper {
  height: 100px;
  textarea {
    height: 100px;
    width: 400px;
    resize: none;
  }
}

.note {
  a.showFormAnchor {
    font-size: 12px;
  }
}

.checkBoxes {
  border: solid 1px gray;
  border-radius: 2px;
  padding: 4px;
  margin: 8px;
  font-size: 12px;
  label {
    margin: 4px;
    color: gray;
    text-decoration: none;
  }
}
