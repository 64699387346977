@use "../../../../assets.scss" as ASSETS;

.window {
  padding: 0;
  width: 80%;
}

.searchRow {
  padding: 8px 8px;
  border-top: solid white 1px;
  border-bottom: solid white 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 8px rgba(255, 255, 255, 0.3);
  input {
    width: 100%;
    font-size: 18px;
    border-radius: 4px;
    padding: 2px 8px;
    margin: 0 4px 0 8px;
    color: white;
    background: rgba(255, 255, 255, 0.1);
    &::placeholder {
      color: #AAA;
    }
  }
  .clearSearchBtn {
    cursor: url(ASSETS.$ASSETS_CURSORS+"/interface.gif"), pointer;
  }
}

.entries {
  min-height: 350px;
  max-height: 600px;
  padding: 8px;
  overflow: auto;
  .noResults {
    margin: 24px 0;
    text-align: center;
  }
}
