@use "../../../globals.scss" as GLOBALS;
@use "../../../zIndexes.scss" as ZINDEXES;

.area {
  z-index: ZINDEXES.$ZINDEX_NOTIFICATION_AREA;
  position: fixed;
  width: 25%;
  min-width: 300px;
  margin-top: GLOBALS.$HEADER_HEIGHT + 8px;
  max-height: calc(100% - #{GLOBALS.$HEADER_HEIGHT});
  right: 8px; // TODO: RTL
  padding: 0 0 0 0;
}

.notification {
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}
