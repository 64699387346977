// TODO: use colors.scss

.notification {
  width: 100%;
  display: block;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0px 0px 8px black;
  color: white;
  $alpha: .7;
  &.INFO { background-color: rgba($color: black, $alpha: $alpha); }
  //&.INFO { background-color: rgba($color: #22F, $alpha: $alpha); }
  &.OK { background-color: rgba($color: #080, $alpha: $alpha); }
  &.WARNING { background-color: rgba($color: #CA0, $alpha: $alpha); color: black; }
  &.ERROR { background-color: rgba($color: #A00, $alpha: $alpha); }
}

.contentWrapper {
  padding: 8px;
}

.closeBtn {
  position: absolute;
  right: 16px; // TODO: RTL
}

.iconAndTitle {
  display: flex;
  align-items: center;
  line-height: 24px;
  border-bottom:  solid white 1px;
  padding-bottom: 4px;
  padding-right: 18px; // TODO: RTL
  .icon {
    font-size: 24px;
    margin-right: 8px; // TODO: RTL
  }
  .title {
    font-size: 18px;
  }
}

.notification.WARNING .iconAndTitle {
  border-bottom: solid black 1px;
}

.content {
  padding: 4px;
  text-align: justify;
}

.notification.isTitlelessAndClosable .content {
  margin-right: 24px; // TODO: RTL
}
