@use "sass:math";
@use "../../../zIndexes.scss" as ZINDEXES;

$PAPYRUS_COLOR: rgb(177, 169, 133);

.menu {
  position: fixed;
  z-index: ZINDEXES.$ZINDEX_RESPONSIVE_MENU;
  width: 80%;
  height: 100vh;
  box-sizing: border-box;
  background-color: rgba($color: black, $alpha: .9);
  box-shadow: 8px 0px 8px 0px rgba(0, 0, 0, 0.8);
  .content {
    height: calc(100vh - 2 * 8px);
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }
}

.buttons,
.languages,
.navigation,
.footer {
  border-top: solid #646464 1px;
}

$headerXPadding: 24px;

.header {
  padding: 8px $headerXPadding;
}

.logo {
  flex-grow: 1;
  img {
    width: calc(100% - $headerXPadding);
    max-width: 512px;
  }
}

.closeBtn {
  position: absolute;
  top: 0px;
  right: math.div($headerXPadding, 2); // TODO: RTL
}

.buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0;
  > * { margin: 8px; }
}

.languages {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  > * { margin: 8px; }
  img {
    width: 24px;
  }
}

.navigation {
  flex-grow: 1;
  text-align: start;
  font-weight: bold;
  font-size: 28px;
  white-space: nowrap;
}

.navigation a { display: block; }
.navigation li {
  line-height: 48px;
  padding: 0 28px;
}
.navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* stylelint-disable no-descending-specificity */
/* root level */
.navigation > ul {
  > li {
    color: $PAPYRUS_COLOR;
    &:not(:first-of-type) {
      border-top: solid 1px rgba(116, 101, 87, 0.6);
    }
    // TODO: make levels collapsable when menu is getting bigger
    /*
    &::after {
      height: 8px;
      width: 8px;
      border-left: 3px solid $PAPYRUS_COLOR;
      border-top: 3px solid $PAPYRUS_COLOR;
      transform: translate(0, -50%) rotate(225deg);
      content: "";
      display: inline-block;
      position: relative;
      top: -128px;
      left: -18px;
    }
    */
  }
}

/* 2nd level */
.navigation ul li ul {
  > li {
    font-size: 24px;
    line-height: 48px;
  }
}

.navigation ul ul ul { display: none; /* limit the menu to 2 levels */ }

/* 3rd level */
.navigation ul li ul li ul {
  > li {
    font-size: 16px;
    line-height: 32px;
  }
}
/* stylelint-enable no-descending-specificity */

.footer {
  padding: 8px 0;
  font-size: 12px;
  color: gray;
}

.footer .social,
.footer .links,
.footer .version {
  padding: 8px 0;
}

.footer .social {
  a, a svg {
    height: 48px;
    @media screen and (max-width: 800px) { height: 32px; }
    @media screen and (max-width: 500px) { height: 24px; }
  }
  a {
    margin: 8px 32px;
    @media screen and (max-width: 800px) { margin: 8px 24px; }
    @media screen and (max-width: 500px) { margin: 4px 12px; }
  }
  a svg {
    stroke-width: .5;
  }
  a:hover svg { stroke: white; }
}

.footer .links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  a {
    color: gray;
    margin: 8px;
    white-space: nowrap;
    text-decoration: underline;
    &:hover { color: white; }
  }
}
