@use "../../../../../colors.scss" as COLORS;

.accountControls {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.accountInfo a,
.loggedOutOptions > a {
  color: COLORS.$HEADER_PURPLE;
  transition: filter .2s;
  margin: 2px;
  svg {
    margin: 0 4px;
    min-width: 16px;
  }
  &:hover { filter: drop-shadow(1px 1px 4px COLORS.$HEADER_PURPLE); }
}

.accountInfo {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.accountInfo .icon { font-size: 32px; }

.accountInfo .gmIcon {
  position: absolute;
  margin-left: 28px;
  width: 20px;
  height: 20px;
  filter: drop-shadow(0 0 4px black);
}

.accountInfo .username {
  margin-top: 2px;
  font-size: 12px;
  text-align: center;
}

.loggedOutOptions {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.loggedInOptions {
  // TODO: enable grid-layout when we have more buttons
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  margin: 0 8px;
  button { margin: 2px; }
}
