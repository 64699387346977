$FONT_COLOR: #7E4D32;
$FONT_COLOR2: #462B1C;

.scroll {
  width: 400px;
  height: 450px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  padding: 0 10px;
  color: $FONT_COLOR;
  font-size: 12px;
  background: url("./img/scrolls/login_scroll_colorized.png") no-repeat;
}

.header {
  position: absolute;
  height: 25px;
  margin-top: 12px;
  margin-left: 8px; // TODO: RTL
  font-weight: bold;
  font-size: 20px;
}

.entries {
  margin: 0 4px;
  margin-top: 55px;
  overflow: auto;
  height: 342px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  height: 45px;
  line-height: 45px;
  text-align: right; // TODO: RTL
  padding-right: 24px; // TODO: RTL
}

.footer a {
  margin: 0 4px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  color: $FONT_COLOR;
  text-decoration: none;
  text-shadow: 0 1px $FONT_COLOR2;
  &::before { content: "➤"; }
  &:hover {
    text-shadow: 0 1px black;
    color: $FONT_COLOR;
  }
}

.entry {
  display: flex;
  align-items: flex-start;
  min-height: 50px;
  overflow: hidden;
  margin: 8px 4px;
  padding: 4px;
  text-align: start;
  color: $FONT_COLOR2;
  border: solid $FONT_COLOR2 1px;
  border-radius: 2px;
  box-shadow: 1px 1px 4px black;
  .icon {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    path {
      color: $FONT_COLOR;
      stroke: black;
      stroke-width: 12px;
    }
  }
  &.INFO .icon path { color: #88F; }
  &.EVENT .icon path { color: #F88; }
  &.WARNING .icon path { color: #FF8; }
  &.DEVELOPMENT .icon path { color: #8FF; }
  &.BALANCING .icon path { color: #F8F; }
  &.BOSS .icon path { color: #8F8; }
  transition: box-shadow .2s, border-radius .2s;
  &:hover {
    transform: scale(101%);
    border-radius: 5px;
    box-shadow: 2px 4px 4px black;
  }
}

.entry .content {
  margin-left: 8px; // TODO: RTL
  .title {
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 14px;
    .date { font-size: 10px; }
  }
  .text {
    text-align: justify;
    a {
      text-decoration: underline;
      color: $FONT_COLOR2;
    }
  }
}
