@use "../../../../../globals.scss" as GLOBALS;
@use "../../../../../colors.scss" as COLORS;

/* stylelint-disable no-descending-specificity */

.navigation {
  font-family: "fhx3", sans-serif;
  font-size: 24px;
  letter-spacing: 1px;
  white-space: nowrap;
}

.navigation a,
.navigation label {
  display: block;
  padding: 0 8px;
  color: COLORS.$HEADER_PURPLE;
  text-decoration: none;
  // text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, .2));
  &:hover { text-decoration: none; }
}

.navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navigation li {
  background-image: linear-gradient(
    to bottom,
    rgba(255,255,255,1) 0%,
    rgba(241,241,241,1) 50%,
    rgba(225,225,225,1) 51%,
    rgba(246,246,246,1) 100%
  );
  border-left: solid white 1px;
  border-right: solid #CCC 1px;
  &.disabled > *:not(ul) { color: #AAA; }
  &:not(.disabled):hover {
    transition: 0.5s;
    background-size: auto 200%;
    background-position: bottom center;
    &> ul { display: inline; }
    &> *:not(ul) {
      filter: drop-shadow(1px 1px 2px COLORS.$HEADER_PURPLE);
    }
  }
}

// root level
.navigation > ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navigation > ul > li {
  line-height: GLOBALS.$HEADER_HEIGHT - 1px;
  &.hasSubEntries > a:after,
  &.hasSubEntries > label:after {
    content: "\25bc";
    font-size: 0.7em;
    vertical-align: middle;
  }
}

// all sublevels
.navigation > ul > li ul {
  min-width: 150px;
  font-size: 20px;
  line-height: 1.5em; // TODO: remove
  display: none;
  position: absolute;
  box-shadow: 0px 4px 5px 3px rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.navigation > ul > li ul li {
  border-top: solid white 1px;
  border-bottom: solid #CCC 1px;
  &:first-of-type { border-top: none; }
  &:last-of-type { border-bottom: none; }
}

// 2nd sublevel
.navigation > ul > li > ul > li > ul {
  display: none !important;
}
