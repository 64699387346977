.reducer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  input, select {
    font-size: 18px;
    border-radius: 4px;
    margin: 2px 4px;
    color: white;
    background-color: black;
  }
}

.entry {
  margin: 4px 8px;
}
