.window {
  max-width: 350px;
  .inputWrapper {
    display: block;
    input { width: 95%; }
  }
}

.spinner {
  text-align: center;
}

.statusIcon {
  text-align: center;
  font-size: 48px;
  &.ok {
    filter: drop-shadow(0px 0px 12px rgba(0, 255, 0, 1));
  }
  &.error {
    filter: drop-shadow(0px 0px 12px rgba(255, 0, 0, 1));
  }
}

.statusIcon,
.description,
.spinner {
  margin: 24px 0;
}
