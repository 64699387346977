$BORDER_RADIUS_PX: 4px;

.tableWrapper {
  display: block;
  border: solid rgba($color: white, $alpha: .4) 1px;
  border-radius: $BORDER_RADIUS_PX;
}

.tableWrapper table {
  width: 100%;
  border: none;
  border-collapse: collapse;
  border-style: hidden;
  color: white;
  td {
    padding: 4px 8px;
    vertical-align: top;
  }
  thead {
    font-weight: bold;
    background-color: rgba($color: white, $alpha: .5);
    td:first-of-type { border-top-left-radius: $BORDER_RADIUS_PX; }
    td:last-of-type { border-top-right-radius: $BORDER_RADIUS_PX; }
  }
  tbody {
    tr:not(:last-of-type) td { border-bottom: solid rgba($color: white, $alpha: .4) 1px; }
    tr:nth-child(even) { background-color: rgba($color: gray, $alpha: .2); }
    tr:nth-child(odd) { background-color: rgba($color: gray, $alpha: .1); }
  }
}
