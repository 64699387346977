.form {
  display: inline-block;
  text-align: center;
}

.figure {
  display: none; // TODO
  z-index: 1;
  width: 471px - 200;
  height: 574px - 200;
  position: absolute;
  background-size: cover;
  background-position: center;
  margin: 335px 0 0 580px;
  filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.4));
  background-image: url("./img/cleric_figure_cut.gif");
}

.window {
  padding: 20px;
  background-repeat: no-repeat, repeat;
  background-size: contain, cover;
  background-position: right -35px bottom -35px, left bottom -35px;
  background-image:
    url("./img/bindstone.png"),
    url("./img/bg-2.jpg");
  box-shadow: 5px 5px 30px 7px black;
}

.instructions {
  display: block;
  margin: 0 auto 20px auto;
  text-align: justify;
  padding: 0 10px;
  button {
    filter: drop-shadow(-5px -5px 10px rgba(150, 200, 255, 0.5));
  }
  ul {
    line-height: 20px;
    li {
      margin: 5px 0;
    }
  }
  li ul li {
    margin: 0px 0;
  }
}

.header {
  display: none;
  font-size: 32px;
  font-family: "fhx4", sans-serif;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > * {
    margin: 15px;
  }
}

.reCaptchaBoxContent {
  width: 304px;
  width: 296px;
  height: 78px;
  margin: -20px;
}

.input {
  z-index: 2;
  opacity: 0.9;
}

.privacyPolicy {
  font-size: 20px;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  .checkBox {
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
  }
}

.placeholder {
  width: 296px;
}

.input:not(.placeholder),
.reCaptchaBox,
.submitButton {
  box-shadow: 2px 2px 10px 5px black;
}
.submitButton {
  margin: 0 auto;
}
